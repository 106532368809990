<template>
  <div>
    <b-table
      striped
      hover
      responsive
      bordered
      :ref="refName"
      :no-select-on-click="noSelectOnClick"
      :selectable="selectable"
      :sticky-header="stickyHeaderHeight"
      :primary-key="primaryKey"
      :tbody-transition-props="transProps"
      :head-variant="headVariant"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :items="items"
      :thead-tr-class="theadTrClass"
      @row-selected="onRowSelect"
      @row-clicked="onRowClick"
      :class="tableClass"
      :tbody-tr-class="tbodyTrClass"
      :fixed="fixed"
      :fields="fields">
      <template v-slot:[`cell(${slot.field})`]="row" v-for="slot in slots">
        <slot :name="slot.name" :tbl="row"></slot>
      </template>
      <template v-slot:[`head(${headSlot.field})`]="head" v-for="headSlot in headSlots">
        <slot :name="headSlot.name" :tbl="head"></slot>
      </template>
      <template v-slot:[defaultSlot.name]="row" v-for="defaultSlot in defaultSlots">
        <slot :name="defaultSlot.name" :tbl="row"></slot>
      </template>
    </b-table>
  </div>
</template>

<script>

  export default {
    name: 'ContentTable',

    data () {
      return {
        sortBy: this.defaultSortBy,
        sortDesc: this.defaultSortDesc,
      }
    },

    props: {
      fields: {
        type: Array,
        required: true
      },
      items: {
        type: Array,
        required: true
      },
      slots: {
        type: Array,
        default: () => []
      },
      headSlots: {
        type: Array,
        default: () => []
      },
      defaultSlots: {
        type: Array,
        default: () => []
      },
      transProps: {
        type: Object,
        default: () => {}
      },
      primaryKey: {
        type: String,
        default: ''
      },
      defaultSortBy: {
        type: String,
        default: ''
      },
      defaultSortDesc: {
        type: Boolean,
        default: false
      },
      tbodyTrClass: {
        required: false
      },
      tableClass: {
        type: String,
        required: false
      },
      stickyHeaderHeight: {
        type: String,
        required: false
      },
      headVariant: {
        type: String,
        required: false
      },
      theadTrClass: {
        type: String,
        required: false
      },
      selectable: {
        type: Boolean,
        default: false,
      },
      refName: {
        type: String,
        default: 'table'
      },
      noSelectOnClick: {
        type: Boolean,
        default: false
      },
      fixed: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      onRowClick (data, index, event) {
        this.$emit('row-clicked', data, event)
      },
      onRowSelect (selectedObjects) {
        this.$emit('row-selected', selectedObjects)
      }
    }
  }
</script>

<style>

</style>
