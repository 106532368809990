import { helpers } from 'vuelidate/lib/validators'
import { withParams } from 'vuelidate/lib/validators/common'

export const alphaSpaces = helpers.regex('alpha', /^[a-zA-ZæøåÆØÅ\s]*$/)

export const marketIdValidator = value => {
  return /^[a-zæøå\d\-_]*$/i.test(value)
}

export const productPrefixValidator = value => {
  return /^([a-z]){3}$/.test(value)
}

export const flexposCompanyIdValidator = value => {
  return /^([0-9]){6}$/.test(value)
}

export const allAlphaLettersValidator = value => {
  return /^[a-zA-ZæøåÆØÅ]*$/.test(value)
}

export const noQuotesValidator = value => {
  if (value)
    return /^[^'"]*$/.test(value)
  return true
}

export const instagramUrlValidator = value => {
  if (value)
    return /^(http(s)?:\/\/)?(www\.)?instagram\.com\/[a-z\d\-_.]{1,255}(\/)?$/i.test(value)
  return true
}

export const facebookUrlValidator = value => {
  if (value)
    return /^(http(s)?:\/\/)?(www\.)?(facebook\.com|fb\.me)\/[a-z\d\-_.?=]{5,255}(\/)?$/i.test(value)
  return true
}

export const urlValidator = value => {
  if (value)
    return /^(http(s)?:\/\/)?(www\.)?([a-z\d-]{1,63}\.)*[a-z\d-]{1,255}\.[a-z]{2,6}(\/)?$/i.test(value)
  return true
}

export const decimalValidator = value => {
  if (value) {
    // eslint-disable-next-line no-undef
    if (LANGUAGE_CODE === 'en')
      return /^(?!0+\.00)(?=.{1,17}(\.|$))\d{1,3}((,)?\d{3})*(\.\d{1,2})?$/.test(value)
    else
      return /^(?!0+\.00)(?=.{1,17}(\.|$))\d{1,3}((\.)?\d{3})*(,\d{1,2})?$/.test(value)
  }
  return true
}

export const betweenValidator = (min, max) => {
  return withParams({
    type: 'betweenValidator',
    min: min,
    max: max,
  }, value => {
    if (value) {
      const number = parseFloat(value.replace(/[.,](?=\d*[.,])/g,''))
      return min <= number && max >= number
    }
    return true
  })
}

export const ibanGermanyValidator = value => {
  if (value)
    return /^DE([0-9a-zA-Z]\s?){20}$/i.test(value)
  return true
}

export const labelsToPrintValidator = value => {
  if (value)
    return /^[0-9]+(?:[-,][0-9]+)*$/g.test(value)
  return true
}

export const UUIDValidator = value => {
  if (value)
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/g.test(value)
  return true
}