import axios from 'axios'
import Cookies from 'js-cookie'

import {
  refreshToken,
  changeLanguage,
  instagramAccountAuth,
  getMarketMedia,
} from '@base/services'
import { mutationTypes, actionTypes } from '@base/store/store-types'
import {
  LOGIN_SECTION_PATH,
  TIME_TOKEN_REFRESH_MILLISECONDS,
  webConfig
} from '@base/configs'
import { encodedObjectUrl } from '@base/utils'
import adminStore from '@admin/store'
import customerStore from '@user/store'

export const setToken = (token) => {
  const key = token.isAdmin ? 'AdminToken' : 'CustomerToken'
  localStorage.setItem(key, JSON.stringify(token))
}

export const getToken = (key) => {
  return JSON.parse(localStorage.getItem(key)) || null
}

export const cleanStorages = (tokenName) => {
  localStorage.removeItem(tokenName)
  delete axios.defaults.headers.common['Authorization']
}

export const refreshTokenCheck = (token, failCallback) => {
  if (!token || !token.date)
    failCallback()

  const expiredInMs = token.expiresIn * 1000, // milliseconds converted to seconds
    createdMs = new Date(token.date).getTime(),
    now = new Date().getTime(),
    diff = now - createdMs

  // Check if token already expired
  if (diff > expiredInMs || diff > TIME_TOKEN_REFRESH_MILLISECONDS) {
    refreshToken({
      data: {
        refresh_token: token.refreshToken,
      }
    }).then(response => {
      if (response.data.error && response.data.error === 'invalid_grant') {
        failCallback()
      }
      const { accessToken, expiresIn, refreshToken } = response.data
      token = { ...token, accessToken, expiresIn, refreshToken }
      token.date = new Date()
      setToken(token)
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
      Cookies.set('authorization', `Bearer ${accessToken}`)
    }).catch(failCallback)
  }
}

const setCsrfToken = (config) => {
  const csrfToken = Cookies.get('csrftoken')
  if (csrfToken)
    config.headers.common['X-CSRFTOKEN'] = csrfToken
}

export const baseRequestInterceptor = (config) => {
  setCsrfToken(config)
  return config
}

export const adminRequestInterceptor = (config) => {
  setCsrfToken(config)
  const adminToken = getToken('AdminToken')
  refreshTokenCheck(adminToken, () => {
    const urlAfterLogout = `${webConfig.baseURL}${LOGIN_SECTION_PATH}`
    adminStore.dispatch(actionTypes.LOGOUT_ADMIN, urlAfterLogout)
  })
  return config
}

export const customerRequestInterceptor = (config) => {
  setCsrfToken(config)
  const customerToken = getToken('CustomerToken')
  refreshTokenCheck(customerToken, () => {
    const urlAfterLogout = customerToken ? `${webConfig.baseURL}/${customerToken.marketId}/` :
      webConfig.baseURL
    customerStore.dispatch(actionTypes.LOGOUT_CUSTOMER, urlAfterLogout)
  })
  return config
}

export const anonRequestInterceptor = (config) => {
  setCsrfToken(config)
  return config
}

export const adminResponseFulfilledInterceptor = (response) => {
  return response
}

export const customerResponseFulfilledInterceptor = (response) => {
  return response
}

export const adminResponseRejectedInterceptor = error => {
  if (error.response.status === 401 || error.response.status === 403) {
    const urlAfterLogout = `${webConfig.baseURL}${LOGIN_SECTION_PATH}`
    adminStore.dispatch(actionTypes.LOGOUT_ADMIN, urlAfterLogout)
  }
  return Promise.reject(error)
}

export const customerResponseRejectedInterceptor = error => {
  if (error.response.status === 401 || error.response.status === 403) {
    const customerToken = getToken('CustomerToken')
    const urlAfterLogout = customerToken ? `${webConfig.baseURL}/${customerToken.marketId}/` :
      webConfig.baseURL
    customerStore.dispatch(actionTypes.LOGOUT_CUSTOMER, urlAfterLogout)
  }
  return Promise.reject(error)
}

const axiosInstances = [
  refreshToken,
  changeLanguage,
  instagramAccountAuth,
  getMarketMedia,
]

for (const instance of axiosInstances) {
  instance.interceptors.request.use(baseRequestInterceptor)
}

export const baseState = {
  showLoader: true,
  user: {
    isAuthenticated: false,
    name: null,
    email: null,
    userType: null,
  },
  customerData: {},
  marketId: '',
  marketInstagramMedia: {},
}

export const baseGetters = {

}

export const baseMutations = {
  [mutationTypes.SET_TOKEN](state, token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token.accessToken}`
    Cookies.set('authorization', `Bearer ${token.accessToken}`)
  },
  [mutationTypes.SET_MARKET_HEADER](state, id) {
    state.marketId = id
  },
  [mutationTypes.SET_LOADER](state, loader) {
    state.showLoader = loader
  },
  [mutationTypes.SET_AUTH](state) {
    state.user.isAuthenticated = true
  },
  [mutationTypes.SET_USER](state, data) {
    state.user.name = data.username
    state.user.email = data.email
  },
  [mutationTypes.SET_USER_DATA](state, data) {
    state.user.name = data.username
    state.user.email = data.email
    state.user.userType = data.userType
  },
  [mutationTypes.SET_MARKET_INSTAGRAM_MEDIA](state, media) {
    state.marketInstagramMedia = media
  },
}

export const baseActions = {
  [actionTypes.INIT_USER]({ commit }, tokenName) {
    const token = getToken(tokenName)
    try {
      if (token && token.accessToken) {
        commit(mutationTypes.SET_TOKEN, token)
        commit(mutationTypes.SET_USER, token)
        commit(mutationTypes.SET_AUTH)
        return Promise.resolve()
      } else {
        return Promise.reject({
          message: 'No token',
          status: 401,
        })
      }
    } catch (e) {
      return Promise.reject({
        message: 'Invalid token',
        status: 401,
      })
    }
  },

  // eslint-disable-next-line no-unused-vars
  [actionTypes.CHANGE_PASSWORD] ({ commit }, { payloads, axiosInstance }) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        data: payloads
      })
        .then((response) => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },

  // eslint-disable-next-line no-unused-vars
  [actionTypes.CHANGE_LANGUAGE]({ commit }, language) {
    // Encode lang data
    const encodedData = encodedObjectUrl(language)
    return new Promise((resolve, reject) => {
      changeLanguage({
        data: encodedData
      })
        .then(response => {
          window.location.reload()
          resolve(response)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },

  // eslint-disable-next-line no-unused-vars
  [actionTypes.DISCONNECT_INSTAGRAM_ACCOUNT]({ commit }) {
    return new Promise((resolve, reject) => {
      instagramAccountAuth({
        url: 'disconnect/',
        method: 'delete'
      })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  [actionTypes.GET_MARKET_INSTAGRAM_MEDIA]({ commit }, mediaId) {
    return new Promise((resolve, reject) => {
      getMarketMedia({
        url: `${mediaId}/`
      })
        .then(response => {
          if (response.data.mediaId) {
            commit(mutationTypes.SET_MARKET_INSTAGRAM_MEDIA, response.data)
            resolve(response.data)
          }
          reject(response.data)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  },
}
