<script>
  export default {
    methods: {
      getTotal (list, fieldName) {
        return list.reduce(function(total, item) {
          // Remove a thousand separators if needed
          const removedThousandSeparatorString = item[fieldName].toString().replace(/[.,](?=\d{3})/g,'')
          const number = parseFloat(removedThousandSeparatorString.replace(/,/g,'.'))
          return total + number
        }, 0)
      }
    }
  }
</script>
