<script>
  import moment from 'moment'
  import { DATE_FORMAT, DATE_TIME_FORMAT } from '@base/configs'

  export default {
    methods: {
      formatNumber (number, precision, acceptZero = false) {
        if (number || (acceptZero && number !== null)) {
          return Number(number).toLocaleString(
            // eslint-disable-next-line no-undef
            LANGUAGE_CODE, {minimumFractionDigits: precision, maximumFractionDigits: precision}
          )
        }
        return ''
      },

      formatDate (rawDate) {
        return moment(rawDate).format(DATE_FORMAT)
      },
      formatDateTime (rawDateTime) {
        return moment(rawDateTime).format(DATE_TIME_FORMAT)
      },
    }
  }
</script>
