<template>
  <b-card
    class="mt-2 custom-card no-margin-left"
    img-blank
    align="center"
    tag="article"
    footer-class="card-footer-container"
  >
    <template v-slot:header>
      <h4
        class="mb-0"
      >
        {{ $t('pages.login.user.card_contact_info.title') }}
      </h4>
    </template>
    <b-card-text>
      <b-list-group flush class="text-left">
        <b-list-group-item
          v-if="market.visualName"
        >
          {{ market.visualName }}
        </b-list-group-item>
        <b-list-group-item
          v-if="market.address && market.addressUrl"
        >
          <a target="blank" :href="market.addressUrl">{{ market.address }}</a>
        </b-list-group-item>
        <b-list-group-item
          v-if="market.supportPhone"
        >
          <a :href='`tel:${market.supportPhone}`'>{{ market.supportPhone }}</a>
        </b-list-group-item>
        <b-list-group-item
          v-if="market.supportEmail"
        >
          <a :href='`mailto:${market.supportEmail}`'>{{ market.supportEmail }}</a>
        </b-list-group-item>
        <b-list-group-item>
          <b-button
            block
            class="align-bottom"
            @click="$bvModal.show('feedback-form')"
            variant="primary">
            {{ $t('pages.feedback_form.button', {
              company_name: market.visualName
            }) }}
          </b-button>
        </b-list-group-item>
      </b-list-group>
    </b-card-text>
    <template v-slot:footer>
      <div class="custom-card-footer">
        <div class="d-inline-flex">
          <div
            class="cursor-pointer"
            v-if="market.instagramUrl"
          >
            <a
              :href="market.instagramUrl"
              target="_blank"
            >
              <font-awesome-icon
                class="social-icons bg-instagram"
                :icon="['fab', 'instagram']" />
            </a>
          </div>
          <div
            v-if="market.facebookUrl"
            class="ml-2 social-icons bg-facebook cursor-pointer"
          >
            <a
              style="color: inherit"
              :href="market.facebookUrl"
              target="_blank"
            >
              <font-awesome-icon
                class="ml-2 mt-1"
                :icon="['fab', 'facebook-f']"
                size="2x" />
            </a>
          </div>
          <div
            v-if="market.websiteUrl"
            class="ml-2 cursor-pointer"
          >
            <a
              :href="market.websiteUrl"
              target="_blank"
            >
              <b-icon icon="globe" font-scale="2.15" variant="dark" />
            </a>
          </div>
        </div>
      </div>

    </template>

    <feedback-modal
      :market="market"
      modalId="feedback-form"
      @formSubmit="formSubmit"
    />

  </b-card>
</template>

<script>
  import FeedbackModal from '@base/components/FeedbackModal.vue'

  export default {
    name: 'MarketContactInfoCard',

    components: {
      FeedbackModal
    },

    data () {
      return {
      }
    },

    props: {
      market: {
        type: Object,
        required: true,
      },
      marketFeedback: {
        required: true,
      },
      instagramMediaId: {
        required: false
      }
    },

    methods: {
      formSubmit (payloads) {
        this.loader = this.$loading.show(this.$_loaderOptions)
        payloads['marketId'] = this.market.marketId
        if (this.instagramMediaId) {
          payloads['instagramMediaId'] = this.instagramMediaId
        }
        this.marketFeedback({
          data: payloads
        })
          .then(() => {
            this.$nextTick(() => {
              this.$bvModal.hide('feedback-form')
            })
            this.$notify({
              group: 'app',
              type: 'success',
              title: this.$t('notifications.title.success'),
              text: this.$t('notifications.text.feedback.success_feedback_sent')
            })
          })
          .catch((error) => {
            this.$_notifyError(error, this)
          })
          .finally(() => {
            this.loader.hide()
          })
      }
    }
  }
</script>

<style>

</style>
