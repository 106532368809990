import { apiConfig, SYSTEM_NAME } from '@base/configs'
import i18n from '@base/i18n'

export const customerApiUrl = `${apiConfig.baseURL}/customer`

export const PRODUCT_NAME_MAX_LENGTH = 24
export const PRODUCT_DESCRIPTION_MAX_LENGTH = 200

export const SHEET_DRAFT = 1
export const SHEET_READY = 2
export const SHEET_PROCESSING = 3
export const SHEET_ACTIVE = 4
export const SHEET_PRINTED = 5

export const PRODUCT_SHEET_STATUSES = [
  {
    code: SHEET_DRAFT,
    name: i18n.t(`multiselect.single_label.product_sheets.${SHEET_DRAFT}`),
  },
  {
    code: SHEET_READY,
    name: i18n.t(`multiselect.single_label.product_sheets.${SHEET_READY}`),
  },
  {
    code: SHEET_PROCESSING,
    name: i18n.t(`multiselect.single_label.product_sheets.${SHEET_PROCESSING}`),
  },
  {
    code: SHEET_ACTIVE,
    name: i18n.t(`multiselect.single_label.product_sheets.${SHEET_ACTIVE}`),
  },
  {
    code: SHEET_PRINTED,
    name: i18n.t(`multiselect.single_label.product_sheets.${SHEET_PRINTED}`),
  },
]

export const PRODUCT_DRAFT = 1
export const PRODUCT_READY = 2
export const PRODUCT_PROCESSING = 3
export const PRODUCT_ACTIVE = 4
export const PRODUCT_PRINTED = 5
export const PRODUCT_SOLD = 6
export const PRODUCT_ACCOUNTED = 7

export const PRODUCT_STATUSES = [
  {
    code: PRODUCT_DRAFT,
    name: i18n.t(`multiselect.single_label.products.${PRODUCT_DRAFT}`),
  },
  {
    code: PRODUCT_READY,
    name: i18n.t(`multiselect.single_label.products.${PRODUCT_READY}`),
  },
  {
    code: PRODUCT_PROCESSING,
    name: i18n.t(`multiselect.single_label.products.${PRODUCT_PROCESSING}`, { systemName: SYSTEM_NAME }),
  },
  {
    code: PRODUCT_ACTIVE,
    name: i18n.t(`multiselect.single_label.products.${PRODUCT_ACTIVE}`, { systemName: SYSTEM_NAME }),
  },
  {
    code: PRODUCT_PRINTED,
    name: i18n.t(`multiselect.single_label.products.${PRODUCT_PRINTED}`),
  },
  {
    code: PRODUCT_SOLD,
    name: i18n.t(`multiselect.single_label.products.${PRODUCT_SOLD}`),
  },
  {
    code: PRODUCT_ACCOUNTED,
    name: i18n.t(`multiselect.single_label.products.${PRODUCT_ACCOUNTED}`),
  },
]

export const EDITABLE_STATUSES = [SHEET_DRAFT, PRODUCT_DRAFT, SHEET_READY, PRODUCT_READY]
export const SOLD_STATUSES = [PRODUCT_SOLD, PRODUCT_ACCOUNTED]

export const THIS_DAY = 0
export const THIS_MONTH = 1
export const LAST_MONTH = 2
export const THIS_YEAR = 3
export const LAST_YEAR = 4

export const SALES_SUMMARY_PERIODS = [
  {
    code: THIS_DAY,
    name: i18n.t(`multiselect.single_label.sales.${THIS_DAY}`),
  },
  {
    code: THIS_MONTH,
    name: i18n.t(`multiselect.single_label.sales.${THIS_MONTH}`),
  },
  {
    code: LAST_MONTH,
    name: i18n.t(`multiselect.single_label.sales.${LAST_MONTH}`),
  },
  {
    code: THIS_YEAR,
    name: i18n.t(`multiselect.single_label.sales.${THIS_YEAR}`),
  },
  {
    code: LAST_YEAR,
    name: i18n.t(`multiselect.single_label.sales.${LAST_YEAR}`),
  },
]