<template>
  <div>
    <b-container>
      <div class="ml-3"><head-block :title="$t('title.admin.user_settings')" /></div>
      <div v-if="isAdminSettings" class="mt-3 mb-3 ml-3">
        <b-button
          variant="outline-primary"
          :to="{ name: 'market-settings' }"
          v-html="$t('links.admin.go_back')"
        >
        </b-button>
      </div>
      <b-row class="col-md-10 col-sm-12 col-lg-12 col-12" cols="1" cols-sm="1" cols-md="1" cols-lg="2">
        <b-col class="mb-5"><change-user-data :isAdminSettings="isAdminSettings" /></b-col>
        <b-col><change-password :isAdminSettings="isAdminSettings" /></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import HeadBlock from '@base/components/HeadBlock'
  import ChangePassword from '@base/components/settings/ChangePassword'
  import ChangeUserData from '@base/components/settings/ChangeUserData'

  export default {
    name: 'SettingsView',
    components: {
      HeadBlock,
      ChangePassword,
      ChangeUserData
    },

    props: {
      isAdminSettings: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style>


</style>
