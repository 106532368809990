import axios from 'axios'
import { userApiConfig, userConfig, webConfig } from '@base/configs'


export const userChangePassword = axios.create({
  method: 'post',
  baseURL: `${webConfig.baseURL}/password/change/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const customerGet = axios.create({
  method: 'get',
  baseURL: `${userConfig.baseURL}/customer/`,
  headers: { 'Accept': 'application/json' },
})

export const updateCustomer = axios.create({
  method: 'patch',
  baseURL: `${userConfig.baseURL}/customer/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

// Market

export const getUserMarket = axios.create({
  method: 'get',
  baseURL: `${userApiConfig.baseURL}/user_market/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

// Sale Lines

export const getUserSaleLines = axios.create({
  method: 'get',
  baseURL: `${userApiConfig.baseURL}/aggregated_sale_lines/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const getUserSaleLinesSummary = axios.create({
  method: 'get',
  baseURL: `${userApiConfig.baseURL}/sale_lines/summary/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

// Product sheets

export const getUserProductSheets = axios.create({
  method: 'get',
  baseURL: `${userApiConfig.baseURL}/product_sheets/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const createUserProductSheet = axios.create({
  method: 'post',
  baseURL: `${userApiConfig.baseURL}/product_sheets/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const updateUserProductSheet = axios.create({
  method: 'patch',
  baseURL: `${userApiConfig.baseURL}/product_sheets/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const deleteUserProductSheet = axios.create({
  method: 'delete',
  baseURL: `${userApiConfig.baseURL}/product_sheets/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})


// Products

export const getUserProducts = axios.create({
  method: 'get',
  baseURL: `${userApiConfig.baseURL}/products/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const createUserProduct = axios.create({
  method: 'post',
  baseURL: `${userApiConfig.baseURL}/products/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const getProductSaleLines = axios.create({
  method: 'get',
  baseURL: `${userApiConfig.baseURL}/product_sale_lines/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

// Campaigns

export const userProductCampaign = axios.create({
  method: 'post',
  baseURL: `${userApiConfig.baseURL}/product_campaigns/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

// Payments

export const getPaymentReports = axios.create({
  method: 'get',
  baseURL: `${userApiConfig.baseURL}/payment_reports/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})