<template>
  <b-container class="mt-3">
    <b-alert
      class="text-center pre-wrap"
      show
      :variant="levelChoices[publicMessageLevel]"
    >
      {{ publicMessage }}
    </b-alert>
  </b-container>
</template>

<script>

  import { LEVEL_CHOICES } from '@base/configs'

  export default {
    name: 'PublicMessage',

    props: {
      publicMessage: {
        type: String,
        required: true,
      },
      publicMessageLevel: {
        type: String,
        required: true,
      }
    },

    data () {
      return  {
        levelChoices: LEVEL_CHOICES,
      }
    },
  }
</script>

<style scoped>

</style>
