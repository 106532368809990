export const encodedObjectUrl = language => {
  return Object.entries(language)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&')
}

export const saveFile = (base64, filename, filetype) => {
  const a = document.createElement('a')
  a.href = 'data:' + filetype + ';base64,' + base64
  a.download = filename
  document.body.appendChild(a)
  a.click()
  setTimeout(() => {
    document.body.removeChild(a)
  }, 0)
}
