import Vue from 'vue'
import Vuex from 'vuex'

import { actionTypes, mutationTypes } from '@base/store/store-types'
import {
  baseState, baseGetters, baseMutations, baseActions,
  customerRequestInterceptor,
  customerResponseFulfilledInterceptor,
  customerResponseRejectedInterceptor,
  getToken,
  setToken
} from '@base/store'

import {
  userChangePassword,
  customerGet,
  updateCustomer,
  getUserMarket,
  getUserSaleLines,
  getUserProductSheets,
  createUserProductSheet,
  updateUserProductSheet,
  deleteUserProductSheet,
  getUserProducts,
  createUserProduct,
  userProductCampaign,
  getProductSaleLines,
  getUserSaleLinesSummary,
  getPaymentReports,
} from '@user/services'
import { webConfig } from '@base/configs'
import { cleanStorages } from '@base/store'
import { revokeToken } from '@base/services'
import i18n from '@base/i18n'

Vue.use(Vuex)

const initialState = {
  ...baseState,

  userNavItems: [],
  userMarket: null,
  userSaleLineSummary: [],
  userSaleLines: {},
  userSaleLinesArg: {
    filters: null
  },
  productSaleLines: [],
  selectedProductSheet: null,
  userProductSheets: [],
  userProductSheetsArg: {
    filters: null,
    page: 1,
    pagesCount: 0,
  },
  userProducts: [],
  userProductsArg: {
    filters: null,
    page: 1,
    pagesCount: 0,
  },
  paymentReports: [],
  paymentReportsArg: {
    page: 1,
    pagesCount: 0,
    filters: null
  },
}

const axiosInstances = [
  userChangePassword,
  customerGet,
  updateCustomer,
  getUserMarket,
  getUserSaleLines,
  getUserSaleLinesSummary,
  getUserProductSheets,
  createUserProductSheet,
  updateUserProductSheet,
  deleteUserProductSheet,
  getUserProducts,
  createUserProduct,
  userProductCampaign,
  getProductSaleLines,
  getPaymentReports,
]

for (const instance of axiosInstances) {
  instance.interceptors.request.use(customerRequestInterceptor)
  instance.interceptors.response.use(
    customerResponseFulfilledInterceptor,
    customerResponseRejectedInterceptor
  )
}

document.initialState = initialState      // Need in each section, stores for base logout user behavior

export default new Vuex.Store({
  state: initialState,
  getters: {
    ...baseGetters,

    getUserProductSheetsPage: state => state.userProductSheetsArg.page,
    getUserProductSheetsPagesCount: state => state.userProductSheetsArg.pagesCount,

    getUserProductsPage: state => state.userProductsArg.page,
    getUserProductsPagesCount: state => state.userProductsArg.pagesCount,

    getPaymentReportPage: state => state.paymentReportsArg.page,
    getPaymentReportPagesCount: state => state.paymentReportsArg.pagesCount,

  },

  mutations: {
    ...baseMutations,

    [mutationTypes.SET_USER_MARKET](state, market) {
      state.userMarket = market
    },
    [mutationTypes.SET_CUSTOMER_DATA](state, data) {
      state.customerData = data
    },
    [mutationTypes.SET_CAMPAIGN_REQUEST](state, campaign) {
      state.customerData.campaignRequestName = campaign.name
      state.customerData.campaignRequestDiscount = campaign.discountPercent
    },
    [mutationTypes.LOGOUT_CUSTOMER](state, url) {
      state = document.initialState
      cleanStorages('CustomerToken')
      document.location = url ? url : webConfig.baseURL
    },

    [mutationTypes.SET_USER_NAV_ITEMS](state) {
      state.userNavItems = [
        {
          href: { name: 'sales' },
          text: i18n.t('navigation.customer.sales')
        },
        {
          href: { name: 'settings' },
          text: i18n.t('navigation.general.settings')
        },
        {
          href: '#',
          text: i18n.t('navigation.general.logout')
        }
      ]

      const token = getToken('CustomerToken')
      if (token && (token.flexposApiValid || (token.zettleIntegration && token.zettleApiValid)) && token.individualizedItemsAccess) {
        if (token.customerAccessToProductCreation) {
          state.userNavItems.splice(1, 0, {
            href: { name: 'product-sheets' },
            text: i18n.t('navigation.customer.product_sheets')
          })
        }
        state.userNavItems.splice(1, 0, {
          href: { name: 'products' },
          text: i18n.t('navigation.customer.products')
        })
      }
      if (token && token.paymentsFeature) {
        state.userNavItems.splice(1, 0, {
          href: { name: 'payments' },
          text: i18n.t('navigation.customer.payments')
        },)
      }
    },
    [mutationTypes.SET_SALE_LINES_LIST] (state, lines) {
      state.userSaleLines = lines
    },
    [mutationTypes.SET_SALE_LINES_SUMMARY] (state, summary) {
      state.userSaleLineSummary = summary
    },
    [mutationTypes.SET_SALE_LINES_FILTERS] (state, filters) {
      state.userSaleLinesArg.filters = filters
    },
    [mutationTypes.SET_PRODUCT_SHEET_LIST] (state, sheets) {
      state.userProductSheets = sheets
    },
    [mutationTypes.SET_PRODUCT_SHEET] (state, sheet) {
      state.selectedProductSheet = sheet
      if (sheet) {
        const token = getToken('CustomerToken')
        if (token.zettleIntegration) {
          state.selectedProductSheet.products = sheet.zettleProducts
        } else {
          state.selectedProductSheet.products = sheet.flexposProducts
        }
      }
    },
    [mutationTypes.SET_PRODUCT_SALE_LINE_LIST] (state, lines) {
      state.productSaleLines = lines
    },
    [mutationTypes.SET_PRODUCT_SHEET_FILTERS] (state, filters) {
      state.userProductSheetsArg.filters = filters
    },
    [mutationTypes.SET_PRODUCT_SHEET_PAGE] (state, page) {
      state.userProductSheetsArg.page = page
    },
    [mutationTypes.SET_PRODUCT_SHEET_PAGES_COUNT] (state, count) {
      state.userProductSheetsArg.pagesCount = count
    },
    [mutationTypes.APPEND_PRODUCT_SHEET](state, sheet) {
      state.userProductSheets.unshift(sheet)
    },
    [mutationTypes.DELETE_PRODUCT_SHEET](state, id) {
      const oldProductSheetIndex = state.userProductSheets.findIndex(sheet => sheet.id === id)
      if (oldProductSheetIndex >= 0)
        state.userProductSheets.splice(oldProductSheetIndex, 1)
    },
    [mutationTypes.UPDATE_PRODUCT_SHEET](state, { id, sheet }) {
      const oldProductSheetObj = state.userProductSheets.find(obj => obj.id === id)
      Object.assign(oldProductSheetObj, sheet)
    },
    [mutationTypes.UPDATE_SELECTED_PRODUCT_SHEET](state, sheet) {
      state.selectedProductSheet = sheet
      const token = getToken('CustomerToken')
      if (sheet) {
        if (token.zettleIntegration) {
          state.selectedProductSheet.products = sheet.zettleProducts
        } else {
          state.selectedProductSheet.products = sheet.flexposProducts
        }
      }
    },
    [mutationTypes.SET_PRODUCT_LIST] (state, products) {
      state.userProducts = products
    },
    [mutationTypes.SET_PRODUCT_FILTERS] (state, filters) {
      state.userProductsArg.filters = filters
    },
    [mutationTypes.SET_PRODUCT_PAGE] (state, page) {
      state.userProductsArg.page = page
    },
    [mutationTypes.SET_PRODUCT_PAGES_COUNT] (state, count) {
      state.userProductsArg.pagesCount = count
    },
    [mutationTypes.SET_PAYMENT_REPORTS](state, reports) {
      state.paymentReports = reports
    },
    [mutationTypes.SET_PAYMENT_REPORTS_FILTERS] (state, filters) {
      state.paymentReportsArg.filters = filters
    },
    [mutationTypes.SET_PAYMENT_REPORTS_PAGE] (state, page) {
      state.paymentReportsArg.page = page
    },
    [mutationTypes.SET_PAYMENT_REPORTS_PAGES_COUNT] (state, count) {
      state.paymentReportsArg.pagesCount = count
    },
  },

  actions: {
    ...baseActions,

    [actionTypes.GET_CUSTOMER_DATA]({ commit }) {
      return new Promise((resolve, reject) => {
        customerGet()
          .then((response) => {
            commit(mutationTypes.SET_USER_DATA, response.data)
            commit(mutationTypes.SET_CUSTOMER_DATA, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },

    [actionTypes.CHANGE_CUSTOMER_DATA] ({commit}, payloads) {
      return new Promise((resolve, reject) => {
        updateCustomer({
          data: payloads
        })
          .then((response) => {
            const token = getToken('CustomerToken')
            if (token) {
              token.username = response.data.username
              setToken(token)
              commit(mutationTypes.SET_USER_DATA, response.data)
            } else {
              commit(mutationTypes.LOGOUT_CUSTOMER)
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },

    [actionTypes.LOGOUT_CUSTOMER]({ commit }, url) {
      return new Promise((resolve, reject) => {
        const token = getToken('CustomerToken')
        if (!token || !token.accessToken) {
          commit(mutationTypes.LOGOUT_CUSTOMER)
        }
        revokeToken({
          data: {'token': token.accessToken}
        })
          .then(response => {
            commit(mutationTypes.LOGOUT_CUSTOMER, url)
            resolve(response)
          })
          .catch(error => {
            commit(mutationTypes.LOGOUT_CUSTOMER)
            reject(error.response)
          })
      })
    },

    [actionTypes.GET_USER_MARKET]({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        getUserMarket()
          .then(response => {
            if (response.data.marketId) {
              const token = getToken('CustomerToken')
              if (token) {
                token.flexposApiValid = response.data.flexposApiValid
                token.zettleApiValid = response.data.zettleApiValid
                token.zettleIntegration = response.data.zettleIntegration
                token.individualizedItemsAccess = response.data.individualizedItemsAccess
                token.customerAccessToProductCreation = response.data.customerAccessToProductCreation
                setToken(token)
                commit(mutationTypes.SET_USER_NAV_ITEMS)
              } else {
                dispatch(actionTypes.LOGOUT_CUSTOMER)
              }
              commit(mutationTypes.SET_USER_MARKET, response.data)
              resolve(response.data)
            } else {
              commit(mutationTypes.LOGOUT_CUSTOMER)
            }
          })
          .catch((error) => {
            reject(error.response)
          })
      })
    },

    [actionTypes.GET_SALE_LINES_LIST]({ commit, state }) {
      return new Promise((resolve, reject) => {
        getUserSaleLines({
          params: state.userSaleLinesArg.filters
        })
          .then(response => {
            commit(mutationTypes.SET_SALE_LINES_LIST, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    [actionTypes.GET_SALE_LINES_SUMMARY]({ commit }) {
      return new Promise((resolve, reject) => {
        getUserSaleLinesSummary()
          .then(response => {
            commit(mutationTypes.SET_SALE_LINES_SUMMARY, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },

    [actionTypes.GET_USER_PRODUCT_SHEET_LIST]({ commit, state }) {
      return new Promise((resolve, reject) => {
        getUserProductSheets({
          params: {
            page: state.userProductSheetsArg.page,
            ...state.userProductSheetsArg.filters
          }
        })
          .then(response => {
            commit(mutationTypes.SET_PRODUCT_SHEET_LIST, response.data.results)
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    [actionTypes.GET_USER_PRODUCT_SHEET]({ commit }, id) {
      return new Promise((resolve, reject) => {
        getUserProductSheets({
          url: `${id}/`
        })
          .then(response => {
            commit(mutationTypes.SET_PRODUCT_SHEET, response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    [actionTypes.GET_PRODUCT_SALE_LINE_LIST]({ commit }, id) {
      return new Promise((resolve, reject) => {
        getProductSaleLines({
          url: `${id}/`
        })
          .then(response => {
            commit(mutationTypes.SET_PRODUCT_SALE_LINE_LIST, response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    [actionTypes.CREATE_USER_PRODUCT_SHEET]({ commit }, payloads) {
      return new Promise((resolve, reject) => {
        createUserProductSheet({
          data: payloads
        })
          .then(response => {
            commit(mutationTypes.APPEND_PRODUCT_SHEET, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    [actionTypes.UPDATE_USER_PRODUCT_SHEET]({ commit }, { payloads, id }) {
      return new Promise((resolve, reject) => {
        updateUserProductSheet({
          url: `${id}/`,
          data: payloads
        })
          .then(response => {
            commit(mutationTypes.UPDATE_SELECTED_PRODUCT_SHEET, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    [actionTypes.COMPLETE_USER_PRODUCT_SHEET]({ commit }, id) {
      return new Promise((resolve, reject) => {
        updateUserProductSheet({
          url: `${id}/complete/`,
        })
          .then(response => {
            commit(mutationTypes.UPDATE_PRODUCT_SHEET, { id: id, sheet: response.data })
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    [actionTypes.PRINT_USER_PRODUCT_SHEET]({ commit }, id) {
      return new Promise((resolve, reject) => {
        updateUserProductSheet({
          url: `${id}/print/`,
        })
          .then(response => {
            commit(mutationTypes.UPDATE_PRODUCT_SHEET, { id: id, sheet: response.data })
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    [actionTypes.DELETE_USER_PRODUCT_SHEET]({ commit }, id) {
      return new Promise((resolve, reject) => {
        deleteUserProductSheet({
          url: `${id}/`
        })
          .then(response => {
            commit(mutationTypes.DELETE_PRODUCT_SHEET, id)
            resolve(response)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },

    [actionTypes.GET_USER_PRODUCT_LIST]({ commit, state }) {
      return new Promise((resolve, reject) => {
        getUserProducts({
          params: {
            page: state.userProductsArg.page,
            ...state.userProductsArg.filters
          }
        })
          .then(response => {
            commit(mutationTypes.SET_PRODUCT_LIST, response.data.results)
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    [actionTypes.CREATE_USER_PRODUCTS]({ commit }, { productSheetId, products }) {
      return new Promise((resolve, reject) => {
        createUserProduct({
          url: `${productSheetId}/create_for_product_sheet/`,
          data: products
        })
          .then(response => {
            commit(mutationTypes.SET_PRODUCT_LIST, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },

    [actionTypes.REQUEST_PRODUCT_CAMPAIGN]({ commit }, {userNumber, id}) {
      return new Promise((resolve, reject) => {
        userProductCampaign({
          url: `${userNumber}/${id}/add_request/`
        })
          .then(response => {
            commit(mutationTypes.SET_CAMPAIGN_REQUEST, response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    [actionTypes.REQUEST_PRODUCT_CAMPAIGN_REMOVAL]({ commit }, userNumber) {
      return new Promise((resolve, reject) => {
        userProductCampaign({
          url: `${userNumber}/remove_request/`
        })
          .then(response => {
            commit(mutationTypes.SET_CAMPAIGN_REQUEST, response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },

    [actionTypes.GET_PAYMENT_REPORTS]({ commit, state }) {
      return new Promise((resolve, reject) => {
        getPaymentReports({
          params: {
            page: state.paymentReportsArg.page,
            ...state.paymentReportsArg.filters
          }
        })
          .then(response => {
            commit(mutationTypes.SET_PAYMENT_REPORTS, response.data.results)
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    [actionTypes.DOWNLOAD_PAYMENT_REPORT_PDF]({ commit }, id) {
      return new Promise((resolve, reject) => {
        getPaymentReports({
          method: 'post',
          url: `${id}/download_pdf/`,
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
  }
})
