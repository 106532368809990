<template>
  <b-card
    class="mt-2 no-margin-left custom-card"
    img-blank
    align="center"
    tag="article"
    footer-class="card-footer-container"
  >
    <template v-slot:header>
      <h4
        class="mb-0"
      >
        {{ $t('pages.login.user.card_opening_hours.title') }}
      </h4>
    </template>
    <b-card-text>
      <b-row
        class="justify-content-center m-2"
        v-for="(hours, key) in marketOpeningHours"
        :key="key"
      >
        <b-col cols="6" class="text-left">
          {{ hours.weekdayName }}
        </b-col>
        <b-col v-if="hours.fromHour && hours.toHour" cols="6">
          {{ hours.fromHour }} - {{ hours.toHour }}
        </b-col>
        <b-col v-else cols="6">
          <b>{{ $t('pages.login.user.card_opening_hours.market_closed') }}</b>
        </b-col>
      </b-row>
    </b-card-text>

    <template v-slot:footer>
      <span
        v-if="market.openingHoursExtraInfo"
        class="custom-card-footer pre-wrap"
      >
        {{ market.openingHoursExtraInfo }}
      </span>
    </template>
  </b-card>
</template>

<script>

  import { OPENING_HOURS } from '@base/configs'

  export default {
    name: 'MarketOpeningHoursCard',

    data () {
      return {
        marketOpeningHours: this.$_.cloneDeep(OPENING_HOURS),
      }
    },

    props: {
      market: {
        type: Object,
        required: true,
      }
    },

    created () {
      this.getMarketOpeningHours()
    },

    methods: {
      getMarketOpeningHours () {
        for (const openingHour of this.market.openingHours) {
          const oldOpeningHourObj = this.marketOpeningHours.find(
            obj => obj.weekdayCode === openingHour.weekdayCode
          )
          if (oldOpeningHourObj)
            Object.assign(oldOpeningHourObj, openingHour)
        }
      },
    }
  }
</script>

<style>

</style>
